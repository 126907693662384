.btn-primary,.btn-primary:hover,.btn-primary:focus,.btn-primary:active,.btn-primary:not(:disabled):not(.disabled):active,.btn-primary:not(:disabled):not(.disabled):active:focus,.btn-primary.disabled, .btn-primary:disabled{
  background-color: rgb(5,183,213);
  border-color: rgb(5,183,213);
  color: white;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
  box-shadow: 0 15px 21px rgba(5, 183, 213, 0.2), 0 0 0 1px rgba(5, 183, 213, 0.2);
}

#root {
  height: 100vh;
}

.page {
  display: flex;
  flex:1;
  flex-direction: column;
  background-color: rgb(249,249,249);
  height: 100%;
}

.header {
  display: flex;
  height: 225px;
  background-color: rgb(254,254,254);
  position: sticky;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
  z-index: 999;
}

.login-inputs-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.check-in-button-container {
  position: absolute;
  width:  100%;
  bottom: 1.5rem;
}

.regular-input, .form-control:focus {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(110,110,110);
  color: rgb(110,110,110);
  padding-left: 0;
  padding-bottom: 1rem;
  border-radius: 0;
  text-align: left;
  box-shadow: none;
  outline: 0;
}

.regular-input::-webkit-input-placeholder {
  border: none;
  background-color: transparent;
  color: rgb(110,110,110);
  padding-left: 0;
  padding-bottom: 1rem;
  border-radius: 0;
  font-weight: 400;
  box-shadow: none;
}

.login-input,.login-inputs-container .form-control:focus {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(110,110,110);
  color: rgb(110,110,110);
  padding-bottom: 1rem;
  padding-left: 0;
  border-radius: 0;
  text-align: center;
  font-weight: bold;
  box-shadow: none;
  font-size: 22px;
  outline: 0;
}

.login-input::-webkit-input-placeholder {
  border: none;
  background-color: transparent;
  color: rgb(110,110,110);
  padding-bottom: 1rem;
  border-radius: 0;
  text-align: center;
  font-weight: 400;
  box-shadow: none;
  font-size: 16px;
}

.header-logo {
  height: 50px;
}

.card.box {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
  display: block;
  margin-bottom: 1rem;
  padding: 1.0rem;

  hr {
    margin: 0.5rem 0rem;
  }
}

.modal {
  justify-content: center;
  align-items: center;
  display: flex !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.selected-star {
  background-color: rgb(5,183,213);
}

.not-selected-star {
  background-color: #F4F4F4;
}
